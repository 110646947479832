import { ComponentTheme } from '.';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dashed: true;
  }
}

export const feedbackTheme: ComponentTheme = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
    variants: [
      {
        props: { variant: 'dashed' },
        style: ({ theme }) => ({
          borderStyle: 'dashed',
          borderWidth: 1,
        }),
      },
    ],
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        border: 'none',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1) !important',
      },
    },
  },
};
