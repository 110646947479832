export enum EntityTypeEnum {
  'BUSINESS' = 'directorio-de-empresas',
  'OFFERS' = 'ofertas-de-trabajo',
  'OPPORTUNITIES' = 'oportunidades',
  'PRODUCTS' = 'productos',
  'SERVICES' = 'servicios',
  'IMPORTERS_EXPORTERS' = 'importadoras-exportadoras'
}

export const EntityTypeEnumValues = Object.values(EntityTypeEnum);
