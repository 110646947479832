import { IScheduleDay } from 'modules/common/interfaces';

export const clearTimeRangeWhenIsFullDay = (schedule: IScheduleDay[]) => {
  return schedule.map((scheduleDay) => {
    if (scheduleDay.fullDay) {
      scheduleDay.schedule = [];
    }
    return scheduleDay;
  });
};
