import { Filter, FilterType } from '@dfl/mui-admin-layout';
import { OperatorFilter, TermFilter } from '@dofleini/query-builder';
import MunicipalityFilter from 'modules/common/components/Address/MunicipalityFilter';
import { PROVINCES } from '@dfl/location';
import { BUSINESS_TYPE_ENUM } from 'modules/contact/interfaces';

export const createdATFilter: Filter = {
  filter: 'common:createdAt',
  translate: true,
  type: FilterType.DATE,
  key: 'createdAt',
  field: 'createdAt',
};

export const startDateFilter: Filter = {
  filter: 'common:startDate',
  translate: true,
  type: FilterType.DATE,
  key: 'startDate',
  field: 'startDate',
};

export const endDateFilter: Filter = {
  filter: 'common:endDate',
  translate: true,
  type: FilterType.DATE,
  key: 'endDate',
  field: 'endDate',
};

const STATE_FIELD = 'shipping.address.state';
const MUNICIPALITY_FIELD = 'shipping.address.municipality';

export const getProvincesFilterByField = (field: string = STATE_FIELD) => ({
  filter: 'common:provinces',
  translate: true,
  type: FilterType.FIXED_LIST,
  key: 'pv',
  field,
  options: PROVINCES.map((pv) => ({
    value: pv.state,
    label: pv.name,
  })),
});

export const getMunicipalityFilterByField = (field: string = MUNICIPALITY_FIELD, stateField: string = STATE_FIELD) => ({
  type: FilterType.FIXED_LIST,
  field,
  filter: 'common:municipalities',
  translate: true,
  key: 'mun',
  Component: MunicipalityFilter,
  transform: (value: any) => {
    if (Array.isArray(value)) {
      return new OperatorFilter({
        type: 'OR',
        filters: value?.map(
          (e) =>
            new OperatorFilter({
              type: 'AND',
              filters: [
                new TermFilter({ field, value: e.slice(-2) }),
                new TermFilter({ field: stateField, value: e.slice(2, -2) }),
              ],
            }),
        ),
      });
    }
    return new OperatorFilter({
      type: 'AND',
      filters: [
        new TermFilter({ field, value: value.slice(-2) }),
        new TermFilter({ field: stateField, value: value.slice(2, -2) }),
      ],
    });
  },
});

export const provincesFilter: Filter = getProvincesFilterByField();

export const municipalitiesFilter: Filter = getMunicipalityFilterByField();

export const minPriceFilter: Filter = {
  filter: 'common:minPrice',
  translate: true,
  type: FilterType.NUMBER,
  key: 'minPrice',
  field: 'minPrice',
};

export const maxPriceFilter: Filter = {
  filter: 'common:maxPrice',
  translate: true,
  type: FilterType.NUMBER,
  key: 'maxPrice',
  field: 'maxPrice',
};

export const organizationalFormFilter = (field: string | undefined = 'type.keyword'): Filter => ({
  filter: 'contact:organizational_form',
  translate: true,
  type: FilterType.FIXED_LIST,
  key: 'of',
  field,
  transform: (value) => {
    if (Array.isArray(value)) {
      return new OperatorFilter({
        type: 'OR',
        filters: value?.map((v) => new TermFilter({ field, value: v })),
      }).toQuery();
    }
    return new TermFilter({ field, value }).toQuery();
  },
  options: Object.keys(BUSINESS_TYPE_ENUM).map((type) => ({
    value: type,
    translate: true,
    label: `contact:businessType.${type}`,
  })),
});
