import { RouteConfig } from '@dfl/react-security';
import { lazy } from 'react';
import { OnboardingModule, EmailAcceptModule } from 'modules/index';
import SignUp from 'modules/authentication/pages/CompleteSignUp';

const loadMainApp = () => import('routes/MainApp');
export const MainApp = lazy(loadMainApp);

const loadAuthApp = () => import('routes/AuthApp');
export const AuthApp = lazy(loadAuthApp);

export const routes: RouteConfig = {
  Auth: {
    path: '/auth/*',
    onlyAnonymous: true,
    component: AuthApp,
  },
  Onboarding: {
    path: '/onboarding/*',
    exact: false,
    authenticated: true,
    component: OnboardingModule,
  },
  ConfirmEmail: {
    path: '/accept/invitation/:id',
    exact: false,
    authenticated: false,
    component: EmailAcceptModule,
  },
  CompleteSingUp: {
    path: '/complete-signup',
    exact: false,
    authenticated: false,
    component: SignUp,
  },
  MainLayout: {
    path: '*',
    authenticated: true,
    redirect: '/auth/login',
    component: MainApp,
  },
};
