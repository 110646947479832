import { ComponentTheme } from '.';

export const inputsTheme: ComponentTheme = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        '.MuiChip-root': {
          height: '17px',
        },
        borderRadius: '3px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        // '.MuiInputBase-input:not(.MuiInputBase-inputSizeSmall)': { padding: '13px 14px;' },
      },
    },
  },
};
