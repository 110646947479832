import { memo, useEffect } from 'react';
import { H1 } from '@dfl/mui-react-common';
import SignUpContainer from 'modules/authentication/container/SignUpContainer';
import { useTranslation } from 'react-i18next';
import AuthLayout from 'layouts/AuthLayout';
import { useNavigate } from 'react-router';
import { useUser } from '@dfl/react-security';

const SignUp = () => {
  const { t } = useTranslation('authentication');
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.security?.verified) navigate('/');
    // eslint-disable-next-line
  }, []);
  return (
    <AuthLayout>
      <H1 textAlign={'center'}>{t('Completa el Registro')}</H1>
      <SignUpContainer complete />
    </AuthLayout>
  );
};

export default memo(SignUp);
