import React, { memo } from 'react';
import useResendConfirmationForm from 'modules/authentication/hooks/useResendConfirmationForm';
import { SentState } from 'modules/authentication/components/SentState';
import { useNavigate } from 'react-router';

type SignUpSentProps = {
  email: string;
};

const SignUpSent = ({ email }: SignUpSentProps) => {
  const { isLoading, error } = useResendConfirmationForm();
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <SentState
      message='authentication:successSignUp'
      buttonText='Ir a inicio'
      error={error}
      isLoading={isLoading}
      email={email}
      action={goHome}
    />
  );
};

export default memo(SignUpSent);
