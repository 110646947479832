import { COMMON_ERRORS_REF } from 'modules/common/constants';

export const ERRORS = {
  UNAUTHORIZED_INVALID_CREDENTIALS: 'PJA000',
  LOCK_ACCOUNT: 'BJA000',
  UNAUTHORIZED_INVALID_LAST_PASS: '301',
  UNAUTHORIZED_USER_NOT_VERIFIED: 'PJA001',
  UNAUTHORIZED_GLOBAL_CATEGORY: 'PJ0001',
  BAD_CATEGORY_TYPE_FOR_ROOT: 'PJ0002',
  UNAUTHORIZED_SPACE_CATEGORY: 'PJ0003',
  UNAUTHORIZED_MULTI_SPACE: 'PJ0004',
  INVALID_SPACE: 'PJ0005',
  DUPLICATED: 'PJ0006',
  DUPLICATED_USER: 'PJ0008',
  NOTFOUND_OR_VERIFIED: 'PJ0009',
  PASSWORD_HISTORY: 'PJ0010',
  INVALID_DATA_PROVIDED: 'PJ0011',
  EXPIRED_TOKEN: 'PJ0012',
  INVALID_PASSWORD: 'PJ0013',
  ACCOUNT_CONFIRMATION_FAILED: 'E13000',
  USER_NOT_FOUND_OR_VERIFIED: 'E13001',
  RESET_PASSWORD_INVALID_LINK: 'E13002',
  USER_OLD_PASSWORD: 'PJ0015',
  ...COMMON_ERRORS_REF,
};
