import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '@dfl/react-security';
import { useFindOneBusiness } from 'modules/dashboard/hooks/useFindOneBusiness';
import { IBusiness } from 'modules/security/spaces/interfaces/IBusiness';

// Data value of the provider context
type BusinessInfoContextValue = {
  business?: IBusiness;
  isLoading: boolean;
  error?: any;
};
// default value of the context
const defaultValue: BusinessInfoContextValue = {
  isLoading: true,
};

// create context
const BusinessInfoContext = createContext<BusinessInfoContextValue>(defaultValue);

// Proptypes of Provider component
type BusinessInfoContextProps = {
  children: any;
};

/**
 * Provider component
 * */

const BusinessInfoProvider = (props: BusinessInfoContextProps) => {
  const { user } = useUser();
  const { isLoading, data, error } = useFindOneBusiness(user?.space as string);

  const [business, setBusiness] = useState<IBusiness>();

  useEffect(() => {
    if (data) {
      setBusiness(data);
    }
  }, [data, setBusiness]);

  return <BusinessInfoContext.Provider
    value={{ business, isLoading, error }} {...props} />;
};

// Default hooks to retrieve context data
const useBusinessInfo = () => {
  const context = useContext(BusinessInfoContext);
  if (context === undefined) {
    throw new Error('You must be inside a BusinessInfoProvider component');
  }
  return context;
};

export { BusinessInfoProvider, useBusinessInfo };
