import { UploadMediaType } from 'components/UploadFiles/files.services';
import ApiClientService from 'modules/common/service/api-client.service';
import EntityApiService from 'modules/common/service/entity-api.service';

export class UploadService<T> extends EntityApiService<T> {
  upload = (path: string, file: File | undefined): Promise<UploadMediaType> => {
    const formData = new FormData();
    formData.append('file', file as Blob);

    if (file) {
      return this.handleResponse(
        ApiClientService.post(this.getPath(path), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      );
    }

    return Promise.reject(new Error('You must need a userId and a files'));
  };
}

export default new UploadService('/ms-auth/api/storage');
