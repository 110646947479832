import { useQuery } from '@tanstack/react-query';
import { useUser } from '@dfl/react-security';
import { useMemo } from 'react';
import BusinessService from 'modules/dashboard/services/business.service';

export const CURRENT_SPACE_KEY = 'current-spaces';

export const useCurrentSpace = () => {
  const { user } = useUser();

  const { data, refetch, isLoading, ...rest } = useQuery(
    [CURRENT_SPACE_KEY],
    async () => {
      return await BusinessService.currentSpace();
    },
    { enabled: !!user },
  );
  const currentSpace = useMemo(() => {
    if (!data || isLoading) return null;
    if (!data.businessInformation) {
      return { ...data };
    }
    if (!data?.commercialName) {
      data.commercialName = data.name;
    }
    data.isOnboardingComplete = !!data.businessInformation.onBoardingCompleteDate;
    return { ...data };
  }, [data, isLoading]);

  return {
    currentSpace,
    refetch,
    isLoading,
    ...rest,
  };
};
