import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: ['DM Sans', '-apple-system'].join(','),
  h1: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  h2: {
    fontSize: 18,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 500,
  },
  body1: {
    fontSize: 14,
  },
  h6: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.5,
    whiteSpace: 'normal',
    textTransform: 'none',
  },
  caption: {
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '19px',
    whiteSpace: 'normal',
    textTransform: 'none',
    color: '#18244E',
    opacity: 0.8,
  },
};
