import { Theme } from '@mui/material';

export const accordionProps = {
  background: 'transparent',
  borderBottom: '1px solid #18244E1A',
  '&:last-child': {
    borderBottom: 0,
  },
  boxShadow: 'none',
  '&:before': {
    background: 'transparent',
  },
  '& .MuiAccordionSummary-root': {
    color: (theme: Theme) => (theme.palette.mode === 'light' ? '#18244E' : '#bdbdbd'),
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 600,
    flexDirection: 'row-reverse',
    paddingLeft: 0,
    paddingRight: 0,
  },
  '.MuiAccordionDetails-root': {
    color: (theme: Theme) => (theme.palette.mode === 'light' ? '#18244E' : '#bdbdbd'),
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: 300,
    paddingBottom: '35px',
    paddingLeft: 0,
    paddingRight: 0,
  },
};
