import React, { createContext, useContext, useState, useMemo, ReactNode } from 'react';
import { useCurrentSpace } from 'modules/security/spaces/hooks/useCurrentSpace';
import { IBusiness } from '../interfaces/IBusiness';
import { BUSINESS_ONBOARDING_STATUS } from '../constants/onboarding-status';

type CurrentBusinessContextProps = {
  currentSpace: IBusiness | null;
  isLoadingCurrentSpace: boolean;
  isOnboardingComplete: boolean;
  setIsOnboardingComplete: (value: boolean) => void;
}

const CurrentBusinessContext = createContext<CurrentBusinessContextProps>({
  currentSpace: null,
  setIsOnboardingComplete: (value: boolean) => {},
  isLoadingCurrentSpace: false,
  isOnboardingComplete: false,
});

type CurrentBusinessContextProviderProps = {
  children: ReactNode;
};
export const CurrentBusinessContextProvider = ({ children, ...props }: CurrentBusinessContextProviderProps) => {
  const { currentSpace, isLoading: isLoadingCurrentSpace } = useCurrentSpace()
  const [_isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const isOnboardingComplete = useMemo(
    () => _isOnboardingComplete || (currentSpace && currentSpace.onboarding?.status === BUSINESS_ONBOARDING_STATUS.COMPLETED),
    [currentSpace, _isOnboardingComplete]
  );

  return (
    <CurrentBusinessContext.Provider {...props} value={{
      currentSpace,
      isLoadingCurrentSpace,
      isOnboardingComplete,
      setIsOnboardingComplete,
    }}>
      {children}
    </CurrentBusinessContext.Provider>
  );
};

export const useCurrentBusinessContext = () => {
  const ctx = useContext(CurrentBusinessContext);
  if (!ctx) {
    throw Error('The `useCurrentBusinessContext` hook must be called from a descendent of the `CurrentBusinessContext`.');
  }

  return {
    currentSpace: ctx.currentSpace,
    isLoadingCurrentSpace: ctx.isLoadingCurrentSpace,
    isOnboardingComplete: ctx.isOnboardingComplete,
    setIsOnboardingComplete: ctx.setIsOnboardingComplete,
  };
};
