import { memo } from 'react';
import { AppProvider } from 'contexts/AppProvider';
import SettingsProvider from 'contexts/SettingsProvider';
import { BrowserRouter } from 'react-router-dom';
import { ChildrenProps } from '@dfl/mui-react-common';
import { I18Provider } from 'contexts/I18Context';
import DateProvider from 'contexts/DateProvider';
import { BusinessInfoProvider } from 'modules/dashboard/contexts/BusinessInfoContext';

const MainProvider = ({ children }: ChildrenProps) => {
  return (
    <SettingsProvider>
      <BrowserRouter>
        <I18Provider>
          <DateProvider>
            <AppProvider>
              <BusinessInfoProvider>{children}</BusinessInfoProvider>
            </AppProvider>
          </DateProvider>
        </I18Provider>
      </BrowserRouter>
    </SettingsProvider>
  );
};

export default memo(MainProvider);
