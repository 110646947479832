import { ComponentTheme } from '.';

export const navigationTheme: ComponentTheme = {
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        '.MuiButtonBase-root': {
          minWidth: 20,
          textTransform: 'none',
        },
      },
    },
  },
};
