import { memo } from 'react';
import { RouteLoader } from '@dfl/react-security';
import { routes } from './main.routes';
import { BreadcrumbsProvider } from '@dfl/mui-admin-layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CurrentBusinessContextProvider } from 'modules/security/spaces/contexts/CurrentBusinessContext';

const Routes = () => {
  return (
    <CurrentBusinessContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BreadcrumbsProvider>
          <RouteLoader routes={routes} />
        </BreadcrumbsProvider>
      </LocalizationProvider>
    </CurrentBusinessContextProvider>
  );
};

export default memo(Routes);
