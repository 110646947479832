export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 1920,
    xxxl: 2560,
    xxxxl: 3440,
    xxxxxl: 3840,
  },
};
