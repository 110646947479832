export enum PAYMENT_METHOD_ENUM {
  'tropipay' = 'tropipay',
  'transfermovil' = 'transfermovil',
  'enzona' = 'enzona',
  'creditcard' = 'creditcard',
}

export enum PAYMENT_METHOD_GATEWAY {
  'tropipay' = 'tropipay',
  'transfermovil' = 'TM',
  'enzona' = 'enzona',
  'creditcard' = 'creditcard',
}

export const PAYMENT_METHOD_ENUM_VALUES = Object.values(PAYMENT_METHOD_ENUM);
