import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { completeSignUpSchema } from '../schemas/login.schema';
import { useCompleteSignUp } from './useCompleteSignUp';
import { useUser } from '@dfl/react-security';

const useCompleteSignUpForm = () => {
  const { user } = useUser();
  const { register, control, handleSubmit, watch } = useForm({
    resolver: yupResolver(completeSignUpSchema),
    defaultValues: {
      email: user?.email || '',
      identifier: user?._id || '',
      password: '',
      acceptTerms: false,
    },
  });

  const termAcceptance = watch('acceptTerms');

  const { mutate, error, isLoading, isSuccess, data } = useCompleteSignUp();

  return {
    control,
    register,
    error,
    termAcceptance,
    isLoading,
    isSuccess,
    data,
    // @ts-ignore
    onSubmit: handleSubmit(mutate),
  };
};

export default useCompleteSignUpForm;
