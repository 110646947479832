export * from './init-address-value.constant';
export * from './common.columns';
export * from './common.filters';
export * from './init-contact.constant';
export * from './errors.references';
export * from './schedule';
export * from './address';
export * from './entity-type.enum';
export * from './styles';
export * from './payment-method.enums';
export * from './period.enums';

export const OTHER_CATEGORY_ID = 'OTHER';
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_KEY = 'page';
export const PRODUCT_PAGE_KEY = 'product-page';
export const SERVICE_PAGE_KEY = 'service-page';
export const OFFER_PAGE_KEY = 'offer-page';
export const OPPORTUNITY_PAGE_KEY = 'opportunity-page';
