export interface IContactMetadata {
  filters?: Record<string, any>;
  emails?: string | string[];
}

export interface IContact {
  _id?: string;
  name: string;
  description: string;
  type: IContactType;
  metadata?: IContactMetadata;
  enabled?: boolean;
  createdAt?: Date;
  emailCount?: number;
  organizationalForm?: string[];
  businessCategory?: string[];
  address?: {
    state: string[];
    municipality: string[];
  };
}

export enum IContactType {
  MANUALLY_ADDED = 'MANUALLY_ADDED',
  DYNAMIC_LIST = 'DYNAMIC_LIST',
  MY_SUBSCRIBERS = 'MY_SUBSCRIBERS',
}

export enum BUSINESS_TYPE_ENUM {
  PRIVATE_MIPYME = 'PRIVATE_MIPYME',
  GOVERNMENT_MIPYME = 'GOVERNMENT_MIPYME',
  TCP = 'TCP',
  GOVERNMENT_COMPANY = 'GOVERNMENT_COMPANY',
  FOREIGN_INVESTMENT = 'FOREIGN_INVESTMENT',
  FOREIGN_COMPANY = 'FOREIGN_COMPANY',
  COMMERCIAL_COMPANY_CUBAN_CAPITAL = 'COMMERCIAL_COMPANY_CUBAN_CAPITAL',
  ASSOCIATIONS = 'ASSOCIATIONS',
  FOUNDATION = 'FOUNDATION',
  CIVIL_SOCIETY = 'CIVIL_SOCIETY',
  PDL = 'PDL',
  CCS = 'CCS',
  CPA = 'CPA',
  UBC = 'UBC',
  CNA = 'CNA',
  ONG = 'ONG',
  ZEDM = 'ZEDM',
}
export interface IMySubscribers {
  email: string;
  firstName?: string;
  lastName?: string;
  createdAt?: Date;
}
