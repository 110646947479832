import { lazy } from 'react';

const loadAuthenticationModule = () => import('modules/authentication');
export const AuthenticationModule = lazy(loadAuthenticationModule);

const loadSecurityModule = () => import('modules/security');
export const SecurityModule = lazy(loadSecurityModule);

const loadUserAccountModule = () => import('modules/account');

export const UserAccountModule = lazy(loadUserAccountModule);

const loadDashboardModule = () => import('modules/dashboard');
export const DashboardModule = lazy(loadDashboardModule);

const loadSettingsModule = () => import('modules/settings');
export const SettingsModule = lazy(loadSettingsModule);

const loadJobOfferModule = () => import('modules/job-offer');
export const JobOfferModule = lazy(loadJobOfferModule);

const loadOpportunityModule = () => import('modules/opportunity');
export const OpportunityModule = lazy(loadOpportunityModule);

const loadSubscriptionsModule = () => import('modules/subscriptions');
export const SubscriptionsModule = lazy(loadSubscriptionsModule);

const loadProductModule = () => import('modules/product');
export const ProductModule = lazy(loadProductModule);

const loadServiceModule = () => import('modules/service');
export const ServiceModule = lazy(loadServiceModule);

const loadOnboardingModule = () => import('modules/onboarding');
export const OnboardingModule = lazy(loadOnboardingModule);

const loadEmailAcceptModule = () => import('modules/email-accept');
export const EmailAcceptModule = lazy(loadEmailAcceptModule);

const loadGalleryModule = () => import('modules/gallery');
export const GalleryModule = lazy(loadGalleryModule);

const loadContactModule = () => import('modules/contact');
export const ContactModule = lazy(loadContactModule);

const loadCampaignsModule = () => import('modules/campaigns');
export const CampaignsModule = lazy(loadCampaignsModule);
