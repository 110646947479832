import { IBusiness } from 'modules/security/spaces/interfaces/IBusiness';
import { clearTimeRangeWhenIsFullDay } from 'modules/dashboard/utils/clearTimeRangeWhenIsFullDay';
import { ApiClientService, EntityApiService } from 'modules/common/service';
import { IAddress, IScheduleDay } from 'modules/common/interfaces';

class BusinessService extends EntityApiService<IBusiness> {
  currentSpace = () => this.handleResponse(ApiClientService.get(this.getPath('')));

  findBusinessBySpace = (spaceId: string) => {
    return this.handleResponse(
      ApiClientService.get(this.getPath(''), {
        headers: {
          'X-Workspace': spaceId,
        },
      }),
    );
  };

  updateSchedule = (schedule: IScheduleDay[], spaceId: string) => {
    const optimizedSchedule = clearTimeRangeWhenIsFullDay(schedule);
    return this.handleResponse(ApiClientService.patch(this.getPath('/schedule'), optimizedSchedule));
  };

  updateGeneralInfo = (general: any, spaceId: string) => {
    return this.handleResponse(
      ApiClientService.patch(this.getPath('/general'), general, {
        headers: {
          'X-Workspace': spaceId,
        },
      }),
    );
  };

  updateAddressInfo = (address: IAddress, spaceId: string) => {
    return this.handleResponse(
      ApiClientService.patch(this.getPath('/location'), address, {
        headers: {
          'X-Workspace': spaceId,
        },
      }),
    );
  };

  updateContactInfo = (contact: any, spaceId: string) => {
    return this.handleResponse(
      ApiClientService.patch(this.getPath('/contact'), contact, {
        headers: {
          'X-Workspace': spaceId,
        },
      }),
    );
  };
}

export default new BusinessService('/ms-business/api/admin/business');
