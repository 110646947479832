import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import BusinessService from 'modules/dashboard/services/business.service';
import { IBusiness } from 'modules/security/spaces/interfaces/IBusiness';
import { BUSINESS_ONE_KEY } from 'modules/dashboard/constants/queries';
import { useTranslationLanguage } from 'contexts/TranslationContext';

export const useFindOneBusiness = (spaceId: string) => {
  const { data: locale } = useTranslationLanguage();
  const fetch = useCallback(() => BusinessService.findBusinessBySpace(spaceId), [spaceId]);
  return useQuery<IBusiness>([locale, spaceId, BUSINESS_ONE_KEY], fetch, { enabled: !!spaceId });
};
