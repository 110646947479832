
export const weekDaysKeysValues = ['MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT', 'SUN'];

export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const emptySchedule = Array.from({ length: 7 }).map((_, i) => ({
  id: i + 1,
  key: weekDaysKeysValues[i],
  value: false,
  fullDay: false,
  schedule: [],
}));
