import { ErrorType } from '@dfl/mui-react-common';

export const COMMON_ERRORS_REF = {
  NETWORK_ERROR: '00000',
  DUPLICATE_KEY: 'E11000',
  BAD_REQUEST: '400',
  NOT_FOUND: '404',
  FILE_TO_LARGE: '413',
  INVALID_IMAGE_EXTENSIONS: 'EFEXT',
  TRANSFERMOVIL_PAYMENT_FAILED: 'TM0001',
};

export const BAD_REQUEST: ErrorType = {
  title: 'common:errors.badRequestTitle',
  description: 'common:errors.badRequestDescription',
  severity: 'error',
};

export const NETWORK_ERROR: ErrorType = {
  title: 'errors:generalError',
  description: 'errors:networkError',
  severity: 'error',
};

export const NOT_FOUND_ERROR: ErrorType = {
  title: 'common:errors.notFoundTitle',
  description: 'common:errors.notFoundDescription',
  severity: 'error',
};

export const DUPLICATED_ERROR: ErrorType = {
  title: 'common:errors.duplicatedTitle',
  description: 'common:errors.duplicatedDescription',
  severity: 'error',
};

export const FILE_TO_LARGE: ErrorType = {
  title: 'common:errors.fileToLargeTitle',
  description: 'common:errors.fileToLargeDescription',
  severity: 'error',
};

export const INVALID_IMAGE_EXTENSIONS: ErrorType = {
  title: 'common:errors.invalidImageExtension',
  description: 'common:errors.invalidImageExtensionDescription',
  severity: 'error',
};

export const TRANSFERMOVIL_PAYMENT_FAILED: ErrorType = {
  title: 'errors:paymentFailed',
  description: 'errors:transfermovilPaymentFailed',
  severity: 'error',
};

export const mapGetOneErrors = (error: any): ErrorType | undefined => {
  if (error?.statusCode === 400 || error?.statusCode === 404) {
    return NOT_FOUND_ERROR;
  }
};

export const COMMON_ERRORS_MAP = {
  [COMMON_ERRORS_REF.BAD_REQUEST]: BAD_REQUEST,
  [COMMON_ERRORS_REF.NOT_FOUND]: NOT_FOUND_ERROR,
  [COMMON_ERRORS_REF.DUPLICATE_KEY]: DUPLICATED_ERROR,
  [COMMON_ERRORS_REF.FILE_TO_LARGE]: FILE_TO_LARGE,
  [COMMON_ERRORS_REF.NETWORK_ERROR]: NETWORK_ERROR,
  [COMMON_ERRORS_REF.TRANSFERMOVIL_PAYMENT_FAILED]: TRANSFERMOVIL_PAYMENT_FAILED,
  [COMMON_ERRORS_REF.INVALID_IMAGE_EXTENSIONS]: INVALID_IMAGE_EXTENSIONS,
};
