import { useMutation } from '@tanstack/react-query';
import { API_AUTH, ApiClientService, AuthService, useUser } from '@dfl/react-security';

const adminLogin = (register: any) => {
  return AuthService.handleResponse(ApiClientService.post(`${API_AUTH.url}/complete-signup`, { ...register }));
};

export const useCompleteSignUp = () => {
  const { setUser } = useUser();

  const { mutateAsync, mutate, isLoading, isError, data, error, isSuccess } = useMutation(adminLogin, {
    onSuccess: (data) => {
      setUser(data);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  return {
    mutateAsync,
    mutate,
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  };
};
