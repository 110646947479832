import { ComponentTheme } from '.';

export const containersTheme: ComponentTheme = {
  MuiPaper: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        background: palette.mode === 'light' ? '#ffffff' : '#222b36',
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        background: palette.mode === 'light' ? '#ffffff' : 'darkred !important',
      }),
    },
  }
};
